.table_container {
    width: auto;
    margin: 0 auto;
    overflow: auto;
    padding-top: 15px;
}

.dashboard_table {
    width: 100%;
    border: 3px solid #e9e9ea;
    border-radius: 15px;
}

.dashboard_table th {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 10px;
    padding-bottom: 8px;
    font-size: 80%;
    color: #3d3d3d;
    text-align: center;
}

.dashboard_table th.up {
    background-image: url("./images/down_arrow.svg");
}

.dashboard_table th.down {
    background-image: url("./images/up_arrow.svg");
}

.dashboard_table th.default {
    background-image: url("./images/sort.svg");
}

th.up,
th.default,
th.down {
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center right;
}

.dashboard_table td {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 90%;
    padding-top: 17px;
    padding-bottom: 17px;
    width: 1%;
    text-align: center;
    white-space: pre-line;
    word-break: break-all;
    word-wrap: break-word;
}

.dashboard_table td:nth-child(1) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 70%;
}

.dashboard_table tbody tr:hover {
    cursor: default;
    box-shadow: 0 1px 0 0 rgba(58, 57, 57, 0.2);
    color: #363853;
    -webkit-box-shadow: 0 1px 0 0 rgba(58, 57, 57, 0.2);
    -moz-box-shadow: 0 1px 0 0 rgba(58, 57, 57, 0.2);
}