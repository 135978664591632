.button_status_success {
    width: 32px;
    height: 32px;
    position: relative;
    background: #2FCC71;
    mask-image: url("images/checkmark.svg");
}

.button_status_failed {
    width: 32px;
    height: 32px;
    background: #E74C3C;
    mask-image: url("images/exclamationmark.svg");
}

.button_status_failed:hover {
    background-color: red;
    cursor: pointer;
}

.button_status_pending {
    width: 32px;
    height: 32px;
    position: relative;
    background: #abbcd5;
    mask-image: url("images/clock.svg");
}