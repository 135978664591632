@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
    line-height: 1.5;
    background-color: #f1f3fb;
    padding: 0 2rem;
}

input {
    appearance: none;
    border-radius: 0;
}

.login_card {
    margin: 16rem auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 425px;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(153, 153, 153, 0.25);
    padding: 0.75rem;
}

.login_card-heading {
    font-family: "DM Sans", sans-serif;
    position: absolute;
    left: 10%;
    top: 15%;
    right: 10%;
    font-size: 1.75rem;
    font-weight: 700;
    color: #363853;
    line-height: 1.222;
}

.login_card-heading small {
    display: block;
    font-size: 0.75em;
    font-weight: 400;
    margin-top: 0.25em;
}

.login_card-form {
    padding: 2rem 1rem 0;
}

.login_input {
    font-family: "DM Sans", sans-serif;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding-top: 1.5rem;
}

.login_input + .login_input {
    margin-top: 1.5rem;
}

.login_input-label {
    font-family: "DM Sans", sans-serif;
    color: #8597a3;
    position: absolute;
    top: 1.5rem;
    transition: 0.25s ease;
}

.login_input-field {
    border: 0;
    z-index: 1;
    background-color: transparent;
    border-bottom: 2px solid #eee;
    font: inherit;
    font-size: 1.125rem;
    padding: 0.25rem 0;
}

.login_input-field:focus, .login_input-field:valid {
    outline: 0;
    border-bottom-color: #363853;
}

.login_input-field:focus + .login_input-label, .login_input-field:valid + .login_input-label {
    color: #363853;
    transform: translateY(-1.5rem);
}

.login_action {
    margin-top: 2rem;
}

.login_action-button {
    font: inherit;
    font-size: 1.25rem;
    padding: 1em;
    width: 100%;
    font-weight: 500;
    background-color: #363853;
    border-radius: 6px;
    color: #FFF;
    border: 0;
}

.login_action-button:hover {
    outline: 0;
    background-color: #47476b;
    cursor: pointer;
}