.testresult_link_success {
    font-family: "Calibri", sans-serif;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: #42ba96;
    border-radius: 50px 50px 50px 50px;
    padding: 2px 0.5em 3px 5px;
}

.testresult_link_success:link {
    text-decoration: none;
}

.testresult_link_success:visited {
    text-decoration: none;
}

.testresult_link_success:hover {
    text-decoration: underline;
    background-color: #198754;
}

.testresult_link_success:active {
    text-decoration: underline;
}

.testresult_link_failed {
    font-family: "Calibri", sans-serif;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: #df4759;
    border-radius: 50px 50px 50px 50px;
    padding: 2px 12px 3px 12px;
}

.testresult_link_failed:link {
    text-decoration: none;
}

.testresult_link_failed:visited {
    text-decoration: none;
}

.testresult_link_failed:hover {
    text-decoration: underline;
    background-color: #D0342C;
}

.testresult_link_failed:active {
    text-decoration: underline;
}

.testresult_link_pending {
    font-family: "Calibri", sans-serif;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: #abbcd5;
    border-radius: 50px 50px 50px 50px;
    padding: 2px 5px 3px 5px;
}

.testresult_link_pending:link {
    text-decoration: none;
}

.testresult_link_pending:visited {
    text-decoration: none;
}

.testresult_link_pending:hover {
    text-decoration: underline;
    background-color: #506690;
}

.testresult_link_pending:active {
    text-decoration: underline;
}