.unread {
    width: 32px;
    height: 32px;
    background: #ecd24b;
    mask-image: url("./images/chat-warning-svgrepo-com.svg");
}

.unread:hover {
    background-color: steelblue;
    cursor: pointer;
}

.read {
    width: 32px;
    height: 32px;
    background: #363853;
    mask-image: url("./images/message-3-svgrepo-com.svg");
}

.read:hover {
    background-color: steelblue;
    cursor: pointer;
}